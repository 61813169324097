import React, { useContext, useMemo } from 'react';
import update from 'lodash/fp/update';
import orderBy from 'lodash/fp/orderBy';
import flow from 'lodash/fp/flow';
import compact from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import set from 'lodash/fp/set';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import identity from 'lodash/fp/identity';
import invoke from 'lodash/fp/invoke';
import uniqBy from 'lodash/fp/uniqBy';
import groupBy from 'lodash/fp/groupBy';
import flatMap from 'lodash/fp/flatMap';
import values from 'lodash/fp/values';
import Context from '../contexts/QuoteGroupsContext';
import asArray from '../utilities/asArray';
import PickupLocation from '../model/PickupLocation';
import ContainerType from '../model/ContainerType';
import CommodityType from '../model/CommodityType';
import ContainerTypes from '../contexts/ContainerTypes';
import CommodityTypes from '../contexts/CommodityTypes';
import PickupLocations from '../contexts/PickupLocations';
import Ports from '../contexts/Ports';
import Port from '../model/Port';
import Container from '../model/Container';
import Carrier from '../model/Carrier';
import Carriers from '../contexts/Carriers';
import { useQuotesContext } from './QuotesProvider';
import UserRecord from '../model/UserRecord';

interface Props {
  children: React.ReactNode;
}

export interface QuoteGroup {
  id: string;
  sortingId: string;
  dateIssued: Date;
  origin?: Port;
  placeOfDeliveryName?: string;
  destination?: Port;
  placeOfReceiptName?: string;
  containers: Container[];
  commodityTypes: CommodityType[];
  quotes: Quote[];
  assignedUsers: UserRecord[];
}

export interface Quote {
  clientId: string;
  userId: string;
  userNameString: string;
  groupId: string;
  id: string;
  carrier: Carrier;
  dateIssued: Date;
  validityPeriod: { from: Date; to: Date };
  origin: Port;
  placeOfDeliveryName?: string;
  destination: Port;
  placeOfReceiptName?: string;
  containers: Container[];
  commodityTypes?: CommodityType[];
  quoteDetails: QuoteDetail[];
  costDetailRemarks: CostDetailRemark[];
  serviceDetails: ServiceDetail[];
  remarks: Remark[];
  terms: Term[];
  archived?: boolean;
  assignedTo?: UserRecord;
  status?: QuoteStatus;
}

export enum QuoteStatus {
  BOOKED,
  LOST_COS_PRICE_DIFF,
  LOST_COS_EQP_AVAIL,
  LOST_COS_SPACE_AVAIL,
  WAIT_FOR_BOOKING,
  SPACE_CHECKING,
  INDICATION,
}

export enum QuoteStatusText {
  BOOKED = 'Secured, won or booked',
  LOST_COS_PRICE_DIFF = 'Lost because of price difference',
  LOST_COS_EQP_AVAIL = 'Lost because of equipment availability',
  LOST_COS_SPACE_AVAIL = 'Lost because of space availability',
  WAIT_FOR_BOOKING = 'In negotiations - price ok - waiting for booking',
  SPACE_CHECKING = 'Checking space and/or equipment',
  INDICATION = 'Indication only',
}

export interface Term {
  TermLabel?: string;
  TermValue: string;
  TermDetail?: string;
  TermURL?: string;
}

export interface QuoteDetail {
  Pos: string;
  Description: string;
  Currency: string;
  CostValue?: string;
  CostUnit?: string;
  Remark?: string;
  RemarkRef?: string;
}

export interface CostDetailRemark {
  RemarkRef: string;
  RemarkText: string;
}

export interface ServiceDetail {
  Frequency: string;
  Routing: string;
  TransitTime: string;
}

export interface Remark {
  Reefer: string;
  RemarkLabel: string;
  RemarkText: string;
  RemarkTitle: string;
}

const normalizeDateRange = flow(update('from', invoke('toDate')), update('to', invoke('toDate')));

const uniqueCommodityTypes = flow(map(get('commodityType')), filter(identity), uniqBy('id'));

export const normalizeQuote = (
  getContainerType: (id: string) => ContainerType | null,
  getCommodityType: (id: string) => CommodityType | null,
  getPickupLocation: (id: string | null) => PickupLocation | null,
  getPort: (id: string) => Port | null,
  getCarrier: (name: string) => Carrier | null,
) => {
  const normalizeContainer = flow(
    update('containerType', getContainerType),
    container =>
      update('commodityType', commodityType =>
        commodityType.trim() === '0'
          ? { id: get('commodityText')(container), name: get('commodityText')(container) }
          : getCommodityType(commodityType),
      )(container),
    update('pickupLocation', getPickupLocation),
  );

  const normalizeContainers = flow(
    asArray,
    map(normalizeContainer),
    filter(container => container.containerType !== null),
  );

  return flow(
    update('carrier', getCarrier),
    update('dateIssued', invoke('toDate')),
    update('validityPeriod', normalizeDateRange),
    update('origin', getPort),
    update('destination', getPort),
    update('containers', normalizeContainers),
    quote => set('commodityTypes', uniqueCommodityTypes(get('containers')(quote)))(quote),
  );
};

const normalizeQuoteGroups = (
  getContainerType: (id: string) => ContainerType | null,
  getCommodityType: (id: string) => CommodityType | null,
  getPickupLocation: (id: string | null) => PickupLocation | null,
  getPort: (id: string) => Port | null,
  getCarrier: (name: string) => Carrier | null,
) => {
  const normalizeQuotes = flow(
    map(normalizeQuote(getContainerType, getCommodityType, getPickupLocation, getPort, getCarrier)),
    orderBy(get('validityPeriod.from'), 'asc'),
  );

  const normalizeQuoteGroup = flow(quotes => {
    const normalizedQuotes = normalizeQuotes(quotes) as Quote[];

    const normalizedQuote = normalizedQuotes[normalizedQuotes.length - 1];

    return {
      id: normalizedQuote.groupId,
      sortingId: normalizedQuote.id,
      dateIssued: normalizedQuote.dateIssued,
      origin: normalizedQuote.origin,
      destination: normalizedQuote.destination,
      containers: normalizedQuote.containers,
      placeOfDeliveryName: normalizedQuote.placeOfDeliveryName,
      placeOfReceiptName: normalizedQuote.placeOfReceiptName,
      commodityTypes: normalizedQuote.commodityTypes,
      assignedUsers: normalizedQuotes.filter(quote => quote.assignedTo).map(quote => quote.assignedTo),
      quotes: normalizedQuotes,
    };
  });

  return flow(
    groupBy('groupId'),
    values,
    flatMap((group: any[]) => (group[0].groupId ? [group] : group.map(item => [set('groupId', item.id)(item)]))),
    map(normalizeQuoteGroup),
    //orderBy([get('dateIssued'), flow(get('id'), padStart(10))], ['desc', 'desc']),
  ) as (result: Quote[]) => QuoteGroup[];
};

export const getEntity = <T extends { id: string }>(collection: T[] | null | undefined, prop: (i: T) => string) => (
  id: string | null | undefined,
) => (id ? collection?.find(i => prop(i) === id) || ({ id } as T) : null);

const QuoteGroupsProvider: React.FC<Props> = ({ children }) => {
  const containerTypes = useContext(ContainerTypes);
  const commodityTypes = useContext(CommodityTypes);
  const pickupLocations = useContext(PickupLocations);
  const ports = useContext(Ports);
  const carriers = useContext(Carriers);
  const quotes = useQuotesContext()[0];

  const normalize = useMemo(() => {
    const getContainerType = getEntity(containerTypes, containerType => containerType.id);
    const getCommodityType = getEntity(commodityTypes, commodityType => commodityType.id);
    const getPickupLocation = getEntity(pickupLocations, pickupLocation => pickupLocation.id);
    const getPort = getEntity(ports, port => port.id);
    const getCarrier = getEntity(carriers, carrier => carrier.name);

    return normalizeQuoteGroups(getContainerType, getCommodityType, getPickupLocation, getPort, getCarrier);
  }, [containerTypes, commodityTypes, pickupLocations, ports, carriers]);

  const quoteGroups = useMemo(() => (quotes === undefined ? undefined : normalize(quotes)), [quotes, normalize]);

  return <Context.Provider value={quoteGroups}>{children}</Context.Provider>;
};

export default QuoteGroupsProvider;
