import React, { Fragment } from 'react';
import RouteSearch from '../components/RouteSearch';
import Meta from '../components/Meta';

const Routes: React.FC = () => (
  <Fragment>
    <Meta title="Schedule" />
    <RouteSearch />
  </Fragment>
);

export default Routes;
