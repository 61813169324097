import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
    '@media print': {
      fontSize: '10px',
    },
  },
  sizeSmall: {
    '@media print': {
      padding: 0,
    },
  },
};
