import {
  ActivityChangeType,
  ActivityLogUserData,
  ShortChecklistItem,
  ShortChecklistItemValueDocument,
  Stage,
} from './ChecklistItemModel';
import { MentionItem } from 'react-mentions';

export interface ActivityLogItem {
  id?: string;
  comment?: string;
  documents?: ShortChecklistItemValueDocument[] | null;
  checklistItem: ShortChecklistItem;
  stage?: Stage;
  by: ActivityLogUserData;
  at: Date;
  type: ActivityType;
  isInternal: boolean;
  changeType?: ActivityChangeType;
  mentions?: MentionItem[];
}

export enum ActivityType {
  COMMENT,
  ACTIVITY,
}

export interface QuoteActivityModel {
  id?: string;
  comment: string;
  by: ActivityLogUserData;
  at: Date;
  type: ActivityType;
  isInternal: boolean;
  mentions?: MentionItem[];
}
export interface QuoteGroupActivityModel {
  id?: string;
  comment: string;
  by: ActivityLogUserData;
  at: Date;
  type: ActivityType;
  isInternal: boolean;
  mentions?: MentionItem[];
  groupId: string;
}
